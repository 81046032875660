import ApiManager from 'utils/ApiManager';
import * as GraphQLTransforms from 'utils/GraphQLTransforms';

export const initialState = {
  sliderItems: [],
  investmentOffersItems: [],
  statisticsItems: [],
};

export const actionTypes = {
  LOAD_FRONT_DATA_INIT: 'HOME/LOAD_FRONT_DATA_INIT',
  LOAD_FRONT_DATA_SUCCESS: 'HOME/LOAD_FRONT_DATA_SUCCESS',
  LOAD_FRONT_DATA_ERROR: 'HOME/LOAD_FRONT_DATA_ERROR',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_FRONT_DATA_SUCCESS:
      return {
        ...state,
        sliderItems: GraphQLTransforms.getNode(action.data[0]).map((el) => ({
          ...el,
          imageUrl: GraphQLTransforms.imageStyle(el.imageUrl),
          link: GraphQLTransforms.link(el.link),
          title: el.title,
          description: el.description,
        })),
        investmentOffersItems: GraphQLTransforms.getNode(action.data[1])
          .filter((el) => el)
          .map((el) => ({
            title: el.title,
            id: el.id,
            slug: el.slug,
            description: {
              realizationDate: el.fieldRealizationDate,
              address: el.fieldAddress,
            },
            image: GraphQLTransforms.imageStyle(el.image),
            fieldInvestmentUpcoming: el.fieldInvestmentUpcoming,
            fieldInvestmentExternalUrl: el.fieldInvestmentExternalUrl,
            hideInvestmentOnFront: el.hideInvestmentOnFront,
          })),
        statisticsItems: GraphQLTransforms.getNode(
          action.data[2]
        )[0].fieldStatistics.map((el) => ({
          text: el.entity.text,
          bigText: el.entity.bigText,
        })),
      };
    default:
      return state;
  }
};

export const loadFrontData = () => (dispatch) => {
  const actions = {
    onInit: actionTypes.LOAD_FRONT_DATA_INIT,
    onSuccess: actionTypes.LOAD_FRONT_DATA_SUCCESS,
    onError: actionTypes.LOAD_FRONT_DATA_ERROR,
  };

  const requests = [];

  requests.push({
    type: 'graphql',
    query: `
      {
        nodeQuery(
          filter: {
            conditions: [
              {field: "type", value: ["slide"]},
              {field: "status", value: ["1"]},
              {field: "field_slide_type", value: ["front"]},
            ]
          }, 
          sort: [{
            field: "field_slide_weight",
            direction: ASC,
          }]
        ) {
          entities {
            ... on NodeSlide {
              imageUrl: fieldSlideImage {
                derivative(style: MAINPAGESLIDER) {
                  url,
                }
              },
              title: fieldSlideTitle,
              description: fieldSlideDescription
              promo : fieldPromoRef {
                entity {
                  ... on NodeSpecialOffer {
                    id: fieldSpecialOfferPromoId,
                    name: fieldSpecialOfferName,
                    date: fieldSpecialOfferDates {
                      startDate
                      endDate
                    },
                  }
                }
              },
              link: fieldSlideLink {
                entity {
                  ... on ParagraphLink {
                    value: fieldPLinkTitle,
                    href: fieldPLinkAbsolute,
                    route: fieldPLinkRoute,
                    params: fieldPLinkParams {
                      name: first,
                      value: second,
                    }
                  }
                }
              },
            }
          }
        }
      }
    `,
  });

  requests.push({
    type: 'graphql',
    query: `
      {
        nodeQuery(limit: 100, filter: {conditions: [
          {field: "type", value: ["investment"]},
          {field: "field_investment_active", value: ["1"]},
          {field: "promote", value: ["1"]}
        ]}, sort: [{
          field: "field_investment_order"
          direction: ASC
        }]) {
          entities {
            ... on NodeInvestment {
              id: nid,
              slug: fieldInvestmentSlug,
              title,
              fieldRealizationDate,
              fieldAddress,
              fieldInvestmentUpcoming,
              fieldSwapVisualisationPlace,
              fieldInvestmentExternalUrl
              hideInvestmentOnFront: fieldInvestmentHideOnFront
              image: fieldInvestmentFrontBanner {
                derivative(style: INVESTMENTMAINIMAGE) {
                  url
                }
              }
            }
          }
        }
      }
    `,
  });

  requests.push({
    type: 'graphql',
    query: `
      {
        nodeQuery(filter: {conditions: [
          {field: "type", value: ["konfiguracja"]}
        ]}) {
          entities {
            ... on NodeKonfiguracja {
              fieldStatistics {
                entity {
                  ... on ParagraphStatistic {
                    text: fieldStatisticsText,
                    bigText: fieldStatisticBigText, 
                  }
                }
              } 
            }
          }
        }
      }
    `,
  });

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};
